import '../styles/main.css';
import '../styles/tailwind.css';
import 'vite/modulepreload-polyfill';
import './react-init.tsx';

import { Header, Menu, Search } from './header';

import filterBar from './components/woo/filterBar';
import reviews from './components/woo/reviews';
import cart from './components/woo/cart';
import printQuote from './components/woo/printQuote';
import Dropdowns from './blocks/dropdowns';
import ButtonIcons from './blocks/buttonIcon';
import FileField from './blocks/fileField';
import aria from './ada/aria';
import careersSelect from './ada/careersSelect';

Header();
Menu();
Search();
filterBar();
reviews();
cart();
printQuote();
aria();
careersSelect();

const dropdowns = new Dropdowns();
const buttonIcons = new ButtonIcons();
const fileField = new FileField();
