import axios from 'axios';
import React, { useEffect, useState } from 'react';

export default function TireBrand(props) {
  const { preselectedType } = props;
  const { localizedData } = window;
  const [brands, setBrands] = useState([]);
  const [brandType, setbrandType] = useState(preselectedType || 'Retail');
  const [loading, setLoading] = useState(true);
  const [submitting, setsubmitting] = useState(false);
  const [brandValue, setbrandValue] = useState('');

  const fetchBrands = async () => {
    setLoading(true);
    const { data } = await axios.get(`/wp-json/stm/v1/lookup/brand/all?type=${brandType}`);
    setBrands(data);
    setLoading(false);
  };

  const handleSubmitForm = () => {
    setsubmitting(true);
    window.location.href = `${brandValue}?type=${brandType}`;
  };

  const HandleBrandType = (e) => {
    setbrandType(e.target.value);
  };

  useEffect(() => {
    fetchBrands();
  }, [setBrands, brandType]);

  return (
    <div>
      <form onSubmit={(e) => e.preventDefault()}>
        {!preselectedType && (
          <div className="mb-4">
            <label className="mr-4 mb-4 inline-block">
              <input value="Retail" checked={brandType.toLowerCase() === 'retail'} onChange={(value) => HandleBrandType(value)} type="radio" className="mr-2" />
              <span>{localizedData.optionsFields.car_truck_suv}</span>
            </label>
            <label className="mr-4 mb-4 inline-block">
              <input value="Commercial" checked={brandType.toLowerCase() === 'commercial'} onChange={(value) => HandleBrandType(value)} type="radio" className="mr-2" />
              <span>{localizedData.optionsFields.commercial_fleet_rv}</span>
            </label>
          </div>
        )}

        <label className="sr-only" htmlFor="tireBrand">{brandType.toLowerCase() === 'retail' ? localizedData.optionsFields.select_a_retail_tire_brand : localizedData.optionsFields.select_a_commercial_tire_brand}</label>
        <select
          className="text-small text-small border-dark-gray mb-8 w-full border px-2 py-1 text-black"
          name="brand"
          id="tireBrand"
          value={brandValue}
          disabled={loading}
          aria-label={brandType.toLowerCase() === 'retail' ? localizedData.optionsFields.select_a_retail_tire_brand : localizedData.optionsFields.select_a_commercial_tire_brand}
          onChange={(e) => setbrandValue(e.target.value)}
        >
          {loading ? (
            <option value="">{localizedData.optionsFields.loading}</option>
          ) : (
            <option value="">{brandType.toLowerCase() === 'retail' ? localizedData.optionsFields.select_a_retail_tire_brand : localizedData.optionsFields.select_a_commercial_tire_brand}</option>
          )}
          {brands.map((brand) => {
            return (
              <option value={brand.url} key={brand.id}>
                {brand.displayName}
              </option>
            );
          })}
        </select>
        {brandValue ? (
          <button
            onClick={handleSubmitForm}
            disabled={submitting}
            className={`${
              submitting ? 'loading' : ''
            } has-cherry-to-crimson-horizontal-gradient-background icon-wheel-for-btn ml-auto flex cursor-pointer items-center rounded-r-full px-4 py-2 font-bold uppercase text-white md:absolute md:right-0 md:bottom-0 md:m-10 `}
          >
            {submitting ? <span>{localizedData.optionsFields.searching_for_tires}...</span> : <span>{localizedData.optionsFields.find_tires}</span>}
          </button>
        ) : (
          <button
            disabled={submitting}
            className="bg-medium-gray icon-wheel-for-btn ml-auto flex cursor-not-allowed items-center rounded-r-full px-4 py-2 font-bold uppercase text-white md:absolute md:right-0 md:bottom-0 md:m-10"
          >
            <span>{localizedData.optionsFields.find_tires}</span>
          </button>
        )}
      </form>
    </div>
  );
}
